import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.state.auth.token) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logOut')
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => store.state.auth.currentUser 
            && store.state.auth.currentUser.userType === 'Account' ? 
             store.state.auth.currentUser.userRole.includes('Admin')  
                ? import('../views/pages/home/admin-index' )
                : import('../views/pages/home/index')
            : import('../views/pages/users/index')
    },
    {
        path: '/users/:userid/portfolios',
        name: 'user-portfolio',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/home/index'),
        props: true
    },
    {
        path: '/:targetType/:targetId/instruments',
        name: 'instrument-assignment',
        meta: {
            isAllow: (auth) => auth.currentUser.userType === 'Owner' || (auth.currentUser.userType === 'Account' && auth.currentUser.userRole.includes('Admin')),
            authRequired: true,
        },
        component: () => import('../views/pages/instruments/assignment'),
        props: true
    },
    {
        path: '/instruments',
        name: 'instruments',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/instruments/index'),
        props: true
    },
    {
        path: '/instruments/:instrumentId',
        name: 'instrument-view',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/instruments/view'),
        props: true
    },
    {
        path: '/execution/:id',
        name: 'execution',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/execution/index'),
        props: route => Object.assign({}, route.query, route.params),
    },
    {
        path: '/accounts',
        name: 'accounts',
        meta: {
            isAllow: (auth) => auth.permissions.accountPermissions.allowManage,
            authRequired: true
        },
        component: () => import('../views/pages/accounts/index')
    },
    {
        path: '/accounts/:id',
        name: 'accounts-edit',
        meta: {
            isAllow: (auth) => auth.permissions.accountPermissions.allowManage,
            authRequired: true,
        },
        component: () => import('../views/pages/accounts/edit'),
        props: true
    },
    {
        path: '/risk-profile-new',
        name: 'risk-profile-new',
        meta: {
            isAllow: (auth) => auth.currentUser.userType === 'Account',
            authRequired: true
        },
        component: () => import('../views/pages/home/risk-profile-new')
    },
    {
        path: '/portfolios/:id/edit',
        name: 'portfolio-edit',
        meta: {
            isAllow: (auth) => auth.currentUser.userType === 'Account',
            authRequired: true
        },
        component: () => import('../views/pages/portfolios/portfolio-edit'),
        props: true
    },
    {
        path: '/portfolios/:id/tax-loss-harvest',
        name: 'tax-loss-harvest',
        meta: {
            isAllow: (auth) => auth.currentUser.userType === 'Account',
            authRequired: true
        },
        component: () => import('../views/pages/portfolios/tax-loss-harvest-page'),
        props: route => Object.assign({}, route.query, route.params),
    },
    {
        path: '/users',
        name: 'users',
        meta: {
            isAllow: (auth) => auth?.permissions?.userPermissions?.allowManage,
            authRequired: true
        },
        component: () => import('../views/pages/users/index')
    },
    {
        path: '/users/:id',
        name: 'users-edit',
        meta: {
            isAllow: (auth) => auth?.permissions?.userPermissions?.allowManage,
            authRequired: true,
        },
        component: () => import('../views/pages/users/edit'),
        props: true
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: () => import('../views/pages/account/register'),
    //     meta: {
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             if (store.state.auth.token) {
    //                 // Redirect to the home page instead
    //                 next({ name: 'home' })
    //             } else {
    //                 // Continue to the login page
    //                 next()
    //             }
    //         },
    //     },
    // },
   
    // {
    //     path: '/forgot-password',
    //     name: 'Forgot-password',
    //     component: () => import('../views/pages/account/forgot-password'),
    //     meta: {
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             if (store.getters['auth/loggedIn']) {
    //                 // Redirect to the home page instead
    //                 next({ name: 'home' })
    //             } else {
    //                 // Continue to the login page
    //                 next()
    //             }
    //         },
    //     },
    // },
   
    // {
    //     path: '/',
    //     name: 'account-users',
    //     meta: {
    //         role:[ROLES.AccountManager],
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/account-users/index')
    // },
    // {
    //     path: '/',
    //     name: 'accounts',
    //     meta: {
    //         role:[ROLES.C8Manager, ROLES.C8Admin],
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/account-users/index')
    // }
]
