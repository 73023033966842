import http from '../../http'

export const state = {
    currentUser: loadState('c8wealth.auth.current-user'),
    token: window.localStorage.getItem('c8wealth.auth.token'),
    permissions: loadState('c8wealth.auth.permissions') || createEmptyPermissions(),
    managedAccounts: loadState('c8wealth.auth.managed-accounts') || []
}

if(loadState('c8wealth.auth.current-user')) {
    document.getElementById("tradingview-widget-container").style.display = 'block';
}

export const mutations = {
    SET_MANAGED_ACCOUNTS(state, newValue) {
        state.managedAccounts = newValue
        if(newValue) {
            saveState('c8wealth.auth.managed-accounts', newValue);
        }
        else {
            localStorage.removeItem('c8wealth.auth.managed-accounts');
        }
    },
    SET_TOKEN(state, newValue) {
        state.token = newValue
        if(newValue) {
            localStorage.setItem('c8wealth.auth.token', newValue);
        }
        else {
            localStorage.removeItem('c8wealth.auth.token');
        }
    },
    SET_PERMISSIONS(state, newValue) {
        state.permissions = newValue || createEmptyPermissions();
        if(newValue) {
            saveState('c8wealth.auth.permissions', newValue);
        }
        else {
            localStorage.removeItem('c8wealth.auth.permissions');
        }
    },
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        if(newValue) {
            saveState('c8wealth.auth.current-user', newValue)
        }
        else {
            localStorage.removeItem('c8wealth.auth.current-user');
        }
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    token(state) {
        return state.token;
    },
    accountPermissios(state) {
        return state.permissions.accountPermissions;
    },
    userPermissios(state) {
        return state.permissions.userPermissions;
    },
    userType(state) {
        return state.currentUser.userType;
    },
    userRole(state) {
        return state.currentUser.userRole;
    },
    currentUser(state) {
        return state.currentUser;
    },
    managedAccounts(state) {
        return state.currentUser.managedAccounts;
    }
}

export const actions = {
    // Logs in the current user.
   logIn({ dispatch, commit }, { email, password, accountId } = {}) {
         return new Promise( (resolve, reject) => {

            http.post('api/auth', { email, password, accountId }).then((response)=>{
                if(response.data.success) {
                    commit('SET_CURRENT_USER', response.data.user);
                    commit('SET_TOKEN', response.data.token);
                    commit('SET_PERMISSIONS', response.data.permissions);
                    commit('SET_MANAGED_ACCOUNTS', response.data.managedAccounts);
                    dispatch('signalr/connect', null, { root: true });
                    dispatch('calculationSignalr/connect', null, { root: true });
                    document.getElementById("tradingview-widget-container").style.display = 'block';
                    resolve(true);
                } else {
                    resolve(response.data.accounts);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        document.getElementById("tradingview-widget-container").style.display = 'none';
        commit('SET_CURRENT_USER', null);
        commit('SET_TOKEN', null);
        commit('SET_PERMISSIONS', null);
        commit('SET_MANAGED_ACCOUNTS', null);
    }
}

// ===
// Private helpers
// ===

function createEmptyPermissions(){
    return {
        accountPermissions:{
            managedAccounts:[],
            allowManage: false
        }
    };
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}


function loadState(key) {
    const b =  window.localStorage.getItem(key);
    if (b) {
        return JSON.parse(b);
    }
    return null;
}
