import http from '../../http'
import { debounce } from "lodash";

export const state = {
  classifiers: [],
  loading: false,
  processing: {},
  page: 1,
  perPage: 20,
  normalized: false,
  filter: {
    accountId: null,
    userId: null,
    keyword: '',

    mtdFrom:null,
    ytdFrom:null,
    annualisedReturnFrom:null,
    realisedVolatilityFrom:null,
    downsideVolatilityFrom:null,
    maxDrawdownFrom:null,
    winningMonthsFrom:null,
    sharpeRatioFrom:null,
    sortinoRatioFrom:null,
    calmarRatioFrom:null,

    mtdTill:null,
    ytdTill:null,
    annualisedReturnTill:null,
    realisedVolatilityTill:null,
    downsideVolatilityTill:null,
    maxDrawdownTill:null,
    winningMonthsTill:null,
    sharpeRatioTill:null,
    sortinoRatioTill:null,
    calmarRatioTill:null,

    classifiers: {

    }
  },
  order:{
    by: 'Name',
    asc: true
  },
  total: 0,
  items: [],
  hasAccess: {},
  defaults: {},
  settings: {
    mode: 'All'
  }
}

export const mutations = {
  SET_NORMALIZED(state, newValue) {
    state.normalized = newValue;
  },
  SET_ORDER(state, newValue) {
    state.order = newValue;
  },
  SET_USER(state, newValue) {
    state.filter.userId =newValue;
  },
  CLEAR_DATA(state) {
    state.page = 1;
    state.items = [];
  },
  SET_MODE(state, newValue) {
    state.settings.mode =newValue;
  },
  RESET_FILTER(state){

    state.filter.keyword ='';
    state.filter.classifiers = {};

    state.filter.mtdFrom=null;
    state.filter.ytdFrom=null;
    state.filter.annualisedReturnFrom=null;
    state.filter.realisedVolatilityFrom=null;
    state.filter.downsideVolatilityFrom=null;
    state.filter.maxDrawdownFrom=null;
    state.filter.winningMonthsFrom=null;
    state.filter.sharpeRatioFrom=null;
    state.filter.sortinoRatioFrom=null;
    state.filter.calmarRatioFrom=null;

    state.filter.mtdTill=null;
    state.filter.ytdTill=null;
    state.filter.annualisedReturnTill=null;
    state.filter.realisedVolatilityTill=null;
    state.filter.downsideVolatilityTill=null;
    state.filter.maxDrawdownTill=null;
    state.filter.winningMonthsTill=null;
    state.filter.sharpeRatioTill=null;
    state.filter.sortinoRatioTill=null;
    state.filter.calmarRatioTill=null;
    
    state.filter = JSON.parse(JSON.stringify(state.filter));
  },
  ADD_DEFAULT(state, instrumentId) {
    state.defaults[instrumentId] = true;
  },
  REMOVE_DEFAULT(state, instrumentId) {
    state.defaults[instrumentId] = false;
  },
  ADD_ACCESS(state, instrumentId) {
    state.hasAccess[instrumentId] = true;
  },
  REMOVE_ACCESS(state, instrumentId) {
    state.hasAccess[instrumentId] = false;
  },
  SET_CLASSIFIERS(state, newValue) {
    for (const [key] of Object.entries(newValue.map)) {
      let value = newValue.map[key];
      value.map = value.values.reduce((acc,curr)=> (acc[curr.value]=curr.text,acc),{});
    }
    newValue.filterItems = newValue.filterItems.map(x => newValue.map[x]);
    newValue.instrumentItems = newValue.instrumentItems.map(x => newValue.map[x]);
    state.classifiers = newValue;
  },
  SET_TOTAL(state, newValue) {
    state.total = newValue
  },
  SET_LOADING(state, newValue) {
    state.loading = newValue
  },
  SET_PROCESSING(state, newValue) {
    state.processing = newValue
  },
  SET_FILTER(state, newValue) {
    state.filter = newValue;
  },
  SET_SETTINGS(state, newValue) {
    state.settings = newValue
  },
  SET_PAGE(state, newValue) {
    state.page = newValue
  },
  SET_ITEMS(state, newValue) {
    state.items = newValue
  },
  SET_HAS_ACCESS(state, newValue) {
    state.hasAccess = newValue
  },
  SET_DEFAULTS(state, newValue) {
    state.defaults = newValue
  }
}

export const getters = {
  
  normalized(state) {
    return state.normalized;
  },
  isStatFiltered(state) {
    if(state.filter.mtdFrom !== null && state.filter.mtdFrom !== undefined) return true;
    if(state.filter.mtdTill !== null && state.filter.mtdTill !== undefined) return true;
    
    if(state.filter.ytdFrom !== null && state.filter.ytdFrom !== undefined) return true;
    if(state.filter.ytdTill !== null && state.filter.ytdTill !== undefined) return true;

    if(state.filter.annualisedReturnFrom !== null && state.filter.annualisedReturnFrom !== undefined) return true;
    if(state.filter.annualisedReturnTill !== null && state.filter.annualisedReturnTill !== undefined) return true;

    if(state.filter.realisedVolatilityFrom !== null && state.filter.realisedVolatilityFrom !== undefined) return true;
    if(state.filter.realisedVolatilityTill !== null && state.filter.realisedVolatilityTill !== undefined) return true;

    if(state.filter.downsideVolatilityFrom !== null && state.filter.downsideVolatilityFrom !== undefined) return true;
    if(state.filter.downsideVolatilityTill !== null && state.filter.downsideVolatilityTill !== undefined) return true;
    
    if(state.filter.maxDrawdownFrom !== null && state.filter.maxDrawdownFrom !== undefined) return true;
    if(state.filter.maxDrawdownTill !== null && state.filter.maxDrawdownTill !== undefined) return true;

    if(state.filter.winningMonthsFrom !== null && state.filter.winningMonthsFrom !== undefined) return true;
    if(state.filter.winningMonthsTill !== null && state.filter.winningMonthsTill !== undefined) return true;

    if(state.filter.sharpeRatioFrom !== null && state.filter.sharpeRatioFrom !== undefined) return true;
    if(state.filter.sharpeRatioTill !== null && state.filter.sharpeRatioTill !== undefined) return true;

    if(state.filter.sortinoRatioFrom !== null && state.filter.sortinoRatioFrom !== undefined) return true;
    if(state.filter.sortinoRatioTill !== null && state.filter.sortinoRatioTill !== undefined) return true;

    if(state.filter.calmarRatioFrom !== null && state.filter.calmarRatioFrom !== undefined) return true;
    if(state.filter.calmarRatioTill !== null && state.filter.calmarRatioTill !== undefined) return true;


    return false;
  },
  order(state) {
    return state.order;
  },
  total(state) {
    return state.total;
  },
  page(state) {
    return state.page;
  },
  items(state) {
    return state.items;
  },
  hasAccess(state) {
    return state.hasAccess;
  },
  defaults(state) {
    return state.defaults;
  },
  perPage(state) {
    return state.perPage;
  },
  settings(state) {
    return state.settings;
  },
  filter(state) {
    return state.filter;
  },
  loading(state) {
    return state.loading;
  },
  processing(state) {
    return state.processing;
  },
  classifiers(state) {
    return state.classifiers;
  }
}

export const actions = {
  async setNormalized({ dispatch, commit }, value) {
    commit('SET_NORMALIZED', value);
    await dispatch('debounceLoad');
  },
  async setOrder({ dispatch, commit }, value) {
    commit('SET_ORDER', value);
    await dispatch('debounceLoad');
  },
  async setSettings({ dispatch, commit },  settings) {
    commit('SET_SETTINGS', settings);
    await dispatch('load');
  },
  async setFilter({ commit },  filter) {
    commit('SET_FILTER', filter);
  },
  async resetFilter({ commit }) {
    commit('RESET_FILTER');
  },
  async setPage({ dispatch, commit },  page) {
    commit('SET_PAGE', page);
    await dispatch('load');
  },
  debounceLoad: debounce(async ({ dispatch }) =>  {
    await dispatch('load');
  }, 1000),
  addDefault({ dispatch, commit, state }, instrumentId) {
    const o = {...state.processing };
    o[instrumentId] = true;
    commit('SET_PROCESSING', o);
    return new Promise( (resolve, reject) => {
      const req = {instrumentId, accountId: state.filter.accountId, userId: state.filter.userId };
      http.post('api/instrument-default/add', req).then((response)=>{
        const o = {...state.processing };
        o[instrumentId] = false;
        commit('SET_PROCESSING', o);
        commit('ADD_DEFAULT', instrumentId);
        dispatch('debounceLoad')
        resolve(response.data)
      }).catch((error) => {
        const o = {...state.processing };
        o[instrumentId] = false;
        commit('SET_PROCESSING', o);
        reject(error);
      });
    });
  },
  removeDefault({ dispatch, commit, state }, instrumentId) {
    const o = {...state.processing };
    o[instrumentId] = true;
    commit('SET_PROCESSING', o);
    return new Promise( (resolve, reject) => {
      const req = {instrumentId, accountId: state.filter.accountId, userId: state.filter.userId };
      http.post('api/instrument-default/remove', req).then((response)=>{
        const o = {...state.processing };
        o[instrumentId] = false;
        commit('SET_PROCESSING', o);
        commit('REMOVE_DEFAULT', instrumentId);
        dispatch('debounceLoad')
        resolve(response.data)
      }).catch((error) => {
        const o = {...state.processing };
        o[instrumentId] = false;
        commit('SET_PROCESSING', o);
        reject(error);
      });
    });
  },
  addAccess({ dispatch, commit, state }, instrumentId) {
    const o = {...state.processing };
    o[instrumentId] = true;
    commit('SET_PROCESSING', o);
    return new Promise( (resolve, reject) => {
      const req = {instrumentId, accountId: state.filter.accountId, userId: state.filter.userId };
      http.post('api/instrument-access/add', req).then((response)=>{
        const o = {...state.processing };
        o[instrumentId] = false;
        commit('SET_PROCESSING', o);
        commit('ADD_ACCESS', instrumentId);
        dispatch('debounceLoad')
        resolve(response.data)
      }).catch((error) => {
        const o = {...state.processing };
        o[instrumentId] = false;
        commit('SET_PROCESSING', o);
        reject(error);
      });
    });
  },
  removeAccess({ dispatch, commit, state }, instrumentId) {
    const o = {...state.processing };
    o[instrumentId] = true;
    commit('SET_PROCESSING', o);
    return new Promise( (resolve, reject) => {
      const req = {instrumentId, accountId: state.filter.accountId, userId: state.filter.userId };
      http.post('api/instrument-access/remove', req).then((response)=>{
        const o = {...state.processing };
        o[instrumentId] = false;
        commit('SET_PROCESSING', o);
        commit('REMOVE_ACCESS', instrumentId);
        dispatch('debounceLoad')
        resolve(response.data)
      }).catch((error) => {
        const o = {...state.processing };
        o[instrumentId] = false;
        commit('SET_PROCESSING', o);
        reject(error);
      });
    });
  },
  async reload({dispatch, commit}){
    commit('SET_PAGE', 1);
    await dispatch('load');
  },
  loadInstrument({ commit }, id) {
    commit('SET_LOADING', true);
    return new Promise( (resolve, reject) => {
      http.get(`api/instruments/${id}`).then((response)=>{
        commit('SET_LOADING', false);
        resolve(response.data)
      }).catch((error) => {
        commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  load({ commit, state }) {
    commit('SET_LOADING', true);
    return new Promise( (resolve, reject) => {
      const skip = (state.page - 1) * state.perPage;
      const req = {
        skip,
        take: state.perPage,
        keyword: state.filter.keyword,
        classifiers: state.filter.classifiers,
        accountId: state.filter.accountId,
        userId: state.filter.userId,
        showOnlyAccessible: state.settings.mode !== 'All',
        order: state.order,
        normalisedPerformance: state.normalized,
        mtdFrom:state.filter.mtdFrom,
        ytdFrom:state.filter.ytdFrom,
        annualisedReturnFrom:state.filter.annualisedReturnFrom,
        realisedVolatilityFrom:state.filter.realisedVolatilityFrom,
        downsideVolatilityFrom:state.filter.downsideVolatilityFrom,
        maxDrawdownFrom:state.filter.maxDrawdownFrom,
        winningMonthsFrom:state.filter.winningMonthsFrom,
        sharpeRatioFrom:state.filter.sharpeRatioFrom,
        sortinoRatioFrom:state.filter.sortinoRatioFrom,
        calmarRatioFrom:state.filter.calmarRatioFrom,
        mtdTill:state.filter.mtdTill,
        ytdTill:state.filter.ytdTill,
        annualisedReturnTill:state.filter.annualisedReturnTill,
        realisedVolatilityTill:state.filter.realisedVolatilityTill,
        downsideVolatilityTill:state.filter.downsideVolatilityTill,
        maxDrawdownTill:state.filter.maxDrawdownTill,
        winningMonthsTill:state.filter.winningMonthsTill,
        harpeRatioTill:state.filter.harpeRatioTill,
        sortinoRatioTill:state.filter.sortinoRatioTill,
        calmarRatioTill:state.filter.calmarRatioTill
      };
     
      http.post('api/instrument-search', req).then((response)=>{
        commit('SET_TOTAL', response.data.total);
        commit('SET_ITEMS', response.data.items);
        commit('SET_HAS_ACCESS', response.data.hasAccess);
        commit('SET_DEFAULTS', response.data.defaults);
        commit('SET_LOADING', false);
        resolve(response.data)
      }).catch((error) => {
        commit('SET_LOADING', false);
        reject(error);
      });
    });
  },
  loadClassifiers({ commit }) {
    commit('SET_LOADING', true);
    return new Promise( (resolve, reject) => {
      const req = {
        
      };
      http.get('api/instrument-classifiers', { params: req}).then((response)=>{
        commit('SET_CLASSIFIERS', response.data);
        commit('SET_LOADING', false);
        resolve(response.data)
      }).catch((error) => {
        commit('SET_LOADING', false);
        reject(error);
      });
    });
  }
}
